body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin: 0px;
  height: 100%;
  overflow: auto;
  background: #ffffff;
}

html {
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.ellipsis {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.h-100 {
  height: 100%;
}

.wordBreakAll {
  word-break: break-all;
}

.flex-0 {
  flex: 0;
}

.flex-1 {
  flex: 1;
}
/* Useful for debug ui */
/* * {
  outline: 1px solid lightsalmon !important;
} */

.MuiStepConnector-active,
.MuiStepConnector-completed,
.MuiStepConnector-lineVertical {
  display: none !important;
}

.MuiStepContent-root,
.MuiStepConnector-root {
  margin-top: 0 !important;
}

.MuiStepConnector-vertical {
  padding: 0 !important;
}
